<template>
  <div class="building">
    <div class="desc box_padding">
      <h3>行业难题</h3>
      <p>
        建材家居行业做为传统行业之一的产业，产品终端消费过程中消费者、生产企业、流通企业之间存在的问题也日益严重，长久以来的信息不对称，逐渐在建材家居行业产品流通环节中形成了由生产企业、流通企业、消费者构成的三级单向信息传导与互动模式。
      </p>
      <div class="desc_img"></div>
      <p>
        随着互联网的快速发展，假冒伪劣产品、山寨产品等大行其道，让企业深受其害，打假维权取证工作困难，不管消费者还是生产企业，打假维权成本高，效果不明显等种种问题。根据中消协相关数据显示，房产建材类产品的投诉一直居于投诉榜的前五位。
      </p>
      <p>
        产品质量安全问题一直是社会大众所关注的热点问题，如何在产品问题频出后能有处可查、有处追责是消费者们的迫切需求。
      </p>
    </div>
    <div class="desc_2 box_padding">
      <h3>解决方案</h3>
      <div class="desc_img"></div>
      <p>
        以产品物联身份标识码为基础，实现原料管理-工艺流程-物流管理-渠道管理-消费者管理的全链条产品物联信息系统。
      </p>
      <p>
        帮助提高产品制造、工艺及效益，并以产品身份标识码为核心，建立起产品流通及消费环节的大数据，实现产品全链追溯多元化。
      </p>
      <p>
        可实时了解产品真伪，跟踪产品路径，鉴别信息来源，持续有效保护消费者权益。
      </p>
      <p>
        通过加强大数据、物联网、移动互联网等新信息技术在打造追溯体系中的落地应用，规范市场、加强行业自律，同时为保护相关主体的商标权、著作权、专利权等提供信息与渠道支持，也为企业在打假维权和相关执法部门行业监管提供有利的依据，让有效保护消费者和正当企业的合法权益变得更便捷。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuildingMaterials",
};
</script>

<style lang="less" scoped>
.building {
  .box_padding {
    padding: 50px 30px;
  }

  h3 {
    font-size: 50px;
    font-weight: 700;
  }
  p {
    font-size: 35px;
    color: #333;
    margin: 20px 0;
    text-align: justify;
  }
  span {
    font-size: 35px;
    font-weight: 500;
  }
  img {
    width: 100%;
    border-radius: 10px;
    height: 400px;
    border: 2px solid #f0f0f0;
  }
  .desc_img {
    width: 100%;
    height: 400px;
    border: 2px solid #f0f0f0;
    background: url("~@/assets/img/product/building_1.jpg") no-repeat
      center/cover;
    border-radius: 10px;
    margin: 10px 0;
  }
  .desc_2 {
    background-color: #f7f7f7;
    .desc_img {
      height: 250px;
      background: url("~@/assets/img/banner/agriculture.png") no-repeat
        center/cover;
    }
  }
}
</style>